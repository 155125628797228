.line {
	margin: 0px 0px 15px 0px;
	display: flex;
}
.text {
	padding: 0px 10px 0px 0px;
width: 80%;
}
.text h2 {
	margin: 0px 0px 5px 0px;
}
.text p {
	margin: 0px 0px 10px 0px;
	color: var(--grey-color);
}
.action {
	width: 20%;
	display: flex;
	justify-content:center;
	align-items: center;
}
.check {
	padding: 5px;
	width: 30px;
	height: 30px;
	object-fit: contain;
	font-size: 18px;
	display: inline-flex;
	border: 2px solid var(--primary-color);
	background-color: #fff;
	border-radius: 8px;
	color: var(--primary-color);
	font-family: 'Raleway';
}
.check img {
	opacity: 0.3;
	width: 100%;
}
.check.checked img {
	opacity: 1;
}

.back {
	position: fixed;
	top: 33px;
	left: 20px;
	height: 15px;
	display: flex;
	align-items: center;
	object-fit: contain;
}

.back img {
	height: 100%;
	margin: 0px 8px 0px 0px;
}

.permission {
	text-decoration: underline;
	color: #0dc356 !important;
	font-size: 18px;
}