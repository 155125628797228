.screen {
	display: flex;
	flex-direction: column;
	flex: 1;
	min-height: 100vh;
	padding: 50px;
	justify-content:center;
	align-items: center;
	background-color: #fff;
}
.screen p {
	font-size: 30px;
	color: var(--primary-color);
	text-align: center;
	line-height: 150%;
	font-weight: 800;
}
.screen img {
	margin: 30px 0px 0px 0px;
}