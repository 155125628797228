.bar {
	position: relative;
	display: flex;
	justify-content:center;
	align-items: center;
	width: 169px;
	height: 51px;
	border: 2px solid var(--primary-color);
	border-radius: 8px;
}
.progress {
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	width: 1%;
	background-color: var(--primary-color);
}
.bar p {
	font-size: 18px;
	font-weight: 700;
	color: #C6CDD5;
	/*text-shadow: 1px 1px 1px #fff;*/
	z-index: 10;
}