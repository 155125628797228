.overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.35);
	backdrop-filter: blur(5px);
	z-index: 100;
	opacity: 0;
	visibility: hidden;
	transition: all 500ms;
}
.overlay.active {
	opacity: 1;
	visibility: visible;
}
.container {
	/*background-color: #fff;*/
	/*background: var(--primary-color);*/
	/*background: #012043f6;
	color: #fff;*/
	background: rgba(255, 255, 255, 0.9);
	display: flex;
	flex-direction: column;
	padding: 30px;
	width: 300px;
	height: 100%;
	transform: translateX(-300px);
	transition: all 1s;
}
.container.active {
	transform: translateX(0px);
}
.container ul {
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.container ul > li {
	width: 100%;
	text-align: center;
	padding: 15px 0;
	font-size: 25px;
	font-weight: 800;
	cursor: pointer;
}

.container ul > li:hover {
	color: #02408c;
}