.screen {
	position: relative;
	display: flex;
	flex-direction: column;
	flex: 1;
	flex-grow: 1;
	min-height: 100vh;
	height: 100%;
	align-items: center;
	justify-content: space-between;
	padding: 40px 10px;
	cursor: default;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.images {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	/*flex: 0.5 0;*/
	height: 45vh;
	max-width: 80%;
	overflow: hidden;
}


.img360 {
	max-width: 120px;
	overflow: hidden;
	max-height: 100px;
	object-fit: contain;
	margin: 0px 0px 80px 0px;
}

.img360 img, .logo img {
	width: 100%;
	height: 100%;
}
.logo {
	max-width: 450px;
	width: 100%;
	margin: 0px 0px 50px 0px;
}
@media (max-height: 550px){
	.screen {
		padding: 20px 10px;
	}
	.img360 {
		width: 90px;
		overflow: visible;
		margin: 0px 0px 10px 0px;
	}
	.logo {
		max-width: 400px;
		width: 100%;
		margin: 0px 0px 50px 0px;
	}
}
.form {
	display: flex;
	flex: 1 2;
	width: 85%;
	flex-direction: column;
	align-items: center;
	padding: 20px 0px 0px 0px;
	/*justify-content: space-between;*/
}
.form__line {
	margin: 0px 0px 20px 0px;
	max-width: 450px;
	width: 100%;
	z-index: 100;
	background-color: #fff;
}
.form__line h3 {
	text-align: center;
	margin: 0px 0px 25px 0px;
}
.form__line article {
	padding: 0 10px;
	font-size: 16px;
	text-align: center;
	font-weight: 600;
	line-height: 25px;
	color: var(--grey-color);
	margin: 0px 0px 35px 0px;
}
.form__line input {
	width: 100%;
	padding: 15px 20px;
	margin: 5px 0px 0px 0px;
	font-size: 16px;
	font-weight: 600;
	border: 2px solid var(--lightGrey-color);
	border-radius: 8px;
	background-color: #fff;
	/*color: var(--lightGrey-color);*/
	color: var(--primary-color)
}
.error input{
	border: 2px solid red !important;
	outline-color: red !important;
}

.form__line input::placeholder {
	font-weight: 400;
}
.form__line label, .form__line h5 {
	margin: 0px 0px 5px 0px;
	color: var(--primary-color);
	font-weight: bold;
}
.form__line p {
	margin: 10px 0px 0px 0px;
	font-size: 22px;
	color: var(--primary-color);
	letter-spacing: 2px;
	text-align: center;
	font-family: Arial, Helvetica, sans-serif;
}
.form__line h5 {
	font-size: 16px;
	/*text-align: center;*/
}
.resend p {
	margin: 45px 0px 10px 0px;
	font-weight: 600;
	color: var(--primary-color);
	text-align: center;
}
.resend p span {
	color: #0566d5;
	cursor: pointer;
}

.back {
	position: absolute;
	top: 30px;
	left: 20px;
	height: 15px;
	display: flex;
	align-items: center;
	object-fit: contain;
	cursor: pointer;
}

.back img {
	height: 100%;
	margin: 0px 8px 0px 0px;
}

.policy {
	display: flex;
	justify-content: center;
	margin: 20px 0px 0px 0px;
}