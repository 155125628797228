.container h3 {
	padding: 40px 45px 20px 45px;
	font-size: 25px;
	text-align: center;
	font-weight: 800;
	line-height: 140%;
}
.line {
	display: block;
	border-top: 1px solid var(--primary-color);
	padding: 15px;
	text-align: center;
	font-size: 20px;
	color: var(--primary-color);
	font-weight: 500;
}