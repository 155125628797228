.screen {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content:center;
	align-items: center;
	flex-grow: 1;
	height: 100vh;
	background-color: rgb(0, 0, 0);
	color: var(--primary-color);
	cursor: default;
}

.screen:hover {
	color: var(--primary-color)
}

.modal {
	max-width: 450px;
	width: 90%;
	background-color: #fff;
	border-radius: 20px;
}

.modal p, .time {
	padding: 40px 60px 20px 60px;
	font-size: 25px;
	text-align: center;
	font-weight: 800;
	line-height: 140%;
	
}
.time {
	display: flex;
	font-family: Arial, Helvetica, sans-serif;
	justify-content: center;
	padding: 0;
}

.play {
	padding: 15px 60px 30px 60px !important;
	color: var(--primary-color);
	animation-name: playPulse;
	animation-duration: 2s;
	animation-iteration-count: infinite;
}

@keyframes playPulse {
	from {
		transform: scale(0.9);
	}
	to {
		transform: scale(0.9);
	}
	50% {
		transform: scale(1);
	}
}