.page {
	padding: 20px;
}
.page h3 {
	text-align: center;
}
.page ul {
	margin: 15px 0px 15px 40px;
}
.page ul > li {
	list-style: square;
}
.page a {
	color: #0066cc;
}