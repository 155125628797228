.icon {
	width: 25px;
	height: 25px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.icon li {
	width: 100%;
	height: 3px;
	background: var(--primary-color);
}