.light {
	position: fixed;
	right: 5px;
	top: 5px;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	z-index: 1000;
}

.online {
	position: fixed;
	right: 5px;
	top: 5px;
	width: 25px;
	height: 25px;
	object-fit: contain;
	z-index: 1000;
}
.online img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}