.video-js canvas {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
}

.webvr-polyfill-fullscreen-wrapper, .webvr-polyfill-fullscreen-wrapper canvas {
	z-index: 500;
}

.vjs-full-window .video-js.vjs-fullscreen {
	z-index: 90;
}

.video-js .vjs-fullscreen-control,
.video-js .vjs-picture-in-picture-control,
.video-js .vjs-volume-panel {
	display: none;
}

.video-js .vjs-play-control:focus-visible {
	outline-color: transparent;
	outline-width: 0;
}

.video-js .vjs-control-bar {
	background-color: rgba(43, 51, 63, 0.0) !important;
	z-index: 550;
}

.video-js .vjs-play-progress {
	background-color: #FF0000;
}

.video-js .vjs-play-progress:before {
	color: #FF0000;
	font-size: 15px;
	right: -8px;
	top: -6px;
}

.video-js .vjs-button-vr .vjs-icon-placeholder {
	height: 30px;
	width: 30px;
	display: inline-block;
	background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNHB4IiBoZWlnaHQ9IjI0cHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0iI0ZGRkZGRiI+CiAgICA8cGF0aCBkPSJNMjAuNzQgNkgzLjIxQzIuNTUgNiAyIDYuNTcgMiA3LjI4djEwLjQ0YzAgLjcuNTUgMS4yOCAxLjIzIDEuMjhoNC43OWMuNTIgMCAuOTYtLjMzIDEuMTQtLjc5bDEuNC0zLjQ4Yy4yMy0uNTkuNzktMS4wMSAxLjQ0LTEuMDFzMS4yMS40MiAxLjQ1IDEuMDFsMS4zOSAzLjQ4Yy4xOS40Ni42My43OSAxLjExLjc5aDQuNzljLjcxIDAgMS4yNi0uNTcgMS4yNi0xLjI4VjcuMjhjMC0uNy0uNTUtMS4yOC0xLjI2LTEuMjh6TTcuNSAxNC42MmMtMS4xNyAwLTIuMTMtLjk1LTIuMTMtMi4xMiAwLTEuMTcuOTYtMi4xMyAyLjEzLTIuMTMgMS4xOCAwIDIuMTIuOTYgMi4xMiAyLjEzcy0uOTUgMi4xMi0yLjEyIDIuMTJ6bTkgMGMtMS4xNyAwLTIuMTMtLjk1LTIuMTMtMi4xMiAwLTEuMTcuOTYtMi4xMyAyLjEzLTIuMTNzMi4xMi45NiAyLjEyIDIuMTMtLjk1IDIuMTItMi4xMiAyLjEyeiIvPgogICAgPHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgyNHYyNEgwVjB6Ii8+Cjwvc3ZnPgo=) no-repeat left center;
}

.video-js {
	position: relative;
	max-width: 500px;
	width: 100%;
	/*height: 250px;*/

}