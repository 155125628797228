.line {
	display: block;
	border-top: 1px solid var(--primary-color);
	padding: 15px;
	text-align: center;
	font-size: 20px;
	color: var(--primary-color);
	font-weight: 500;
}
.container h3 {
	margin: 15px 0px 0px 0px;
}