.screen {
	flex: 1;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	background-color: #CDD9EA;
	cursor: default;
}

.image {
	height: 45vh;
	min-height: 300px;
	padding: 80px 40px 50px 40px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	z-index: 0;
}

.image h2 {
	font-size: 40px;
	margin: 0px 0px 10px 0px;
	font-weight: 800;
	overflow: hidden;
	text-overflow: ellipsis;
}

.image p {
	font-size: 25px;
	font-weight: 500;
	overflow: hidden;
	text-overflow: ellipsis;
}

.arrow {
	width: 60px;
	height: 60px;
	margin: 0px 0px 15px 0px;
	animation-name: arrowDown;
	animation-duration: 2s;
}

@keyframes arrowDown {
	from {
		transform: rotate(-90deg);
	}

	to {
		transform: rotate(0);
	}
}

.arrow img {
	width: 100%;
	height: 100%;
	transform: rotate(90deg);
}

.info {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	margin: -30px 0px 0px 0px;
	padding: 40px;
	background-color: #fff;
	z-index: 5;
}

.line__duration {
	display: flex;
	align-items: center;
	margin: 0px 0px 25px 0px;
}

.clock {
	width: 30px;
	height: 30px;
	object-fit: contain;
	margin: 0px 20px 0px 0px;
}

.clock img {
	width: 100%;
	height: 100%;
}

.topics {
	display: flex;
	font-size: 20px;
	font-weight: 800;
	align-items: baseline;
}

.topics p {
	font-weight: 400;
	margin: 0px 0px 0px 10px;
	color: var(--primary-color);
}

.info h3 {
	font-size: 25px;
	font-weight: 800;
	margin: 0px 0px 15px 0px;
}

.info p {
	line-height: 150%;
	color: var(--grey-color);
}

.buttons {
	margin: 25px 0px 0px 0px;
	display: flex;
	justify-content: space-between;
}

.back {
	position: absolute;
	top: 30px;
	left: 20px;
	height: 15px;
	display: flex;
	align-items: center;
	object-fit: contain;
}

.back img {
	height: 100%;
	margin: 0px 8px 0px 0px;
}