.screen {
	display: flex;
	flex-direction: column;
	flex: 2;
	min-height: 100vh;
	align-items: center;
	padding: 90px 10px 0px 10px;
	background-color: #CDD9EA;
	cursor: default;
}
.header {
	position: fixed;
	height: 80px;
	top: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content:center;
	align-items: center;
	padding: 0px 60px;
	background: rgba(255, 255, 255, 0.6);
	/*opacity: 1;*/
	-webkit-backdrop-filter: blur(10px);
	backdrop-filter: blur(10px);
	font-size: 25px;
	color: var(--primary-color);
	font-weight: 800;
	z-index: 20;
}
.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 450px;
	width: 100%;
}
.burger {
	position: absolute;
	left: 25px;
	top: 25px;
	width: 25px;
	height: 50px;
	object-fit: contain;
	cursor: pointer;
	z-index: 9999;
}