.overlay {
	background: rgba(0, 0, 0, 0.35);
	backdrop-filter: blur(5px);
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: flex;
	justify-content:center;
	align-items: center;
	transition: all 0.3s ease-in-out;
	opacity: 0;
	visibility: hidden;
	z-index: 1000;
}
.overlay.active {
	opacity: 1;
	visibility: visible;
}
.modal {
	max-width: 450px;
	width: 90%;
	background-color: #fff;
	border-radius: 20px;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.395);
}