.screen {
	display: flex;
	height: 100vh;
	flex-direction: column;
	justify-content:center;
	align-items: center;
	text-align: center;
	padding: 0 15px;
}
.screen p {
	font-weight: 500;
	font-size: 23px;
	margin: 0px 0px 10px 0px;
}
.number {
	font-size: 80px;
	font-weight: 900;
	margin: 0px 0px 15px 0px;
}