.checkbox {
	position: relative;

}

.checkbox:not(:last-child) {
	margin-bottom: em(5);
}

.checkbox__input {
	position: absolute;
	width: 0;
	height: 0;
	opacity: 0;
}

.checkbox__input:focus+.checkbox__label:before {
	box-shadow: 0 0 3px #012043;
}

.checkbox__input:checked+.checkbox__label:before {
	/*background: #012043;*/
	background: rgb(1, 32, 67);
	background: -moz-radial-gradient(circle, rgba(1, 32, 67, 1) 52%, rgba(255, 255, 255, 1) 53%);
	background: -webkit-radial-gradient(circle, rgba(1, 32, 67, 1) 52%, rgba(255, 255, 255, 1) 53%);
	background: radial-gradient(circle, rgba(1, 32, 67, 1) 52%, rgba(255, 255, 255, 1) 53%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#012043", endColorstr="#ffffff", GradientType=1);
}

.checkbox__label {
	cursor: pointer;
	display: inline-flex;
	align-items: center;
	position: relative;
	gap: 10px;
}

.checkbox__label:before {
	content: "";
	align-self: center;
	flex: 0 0 10px;
	width: 10px;
	height: 10px;
	border: 1px solid #012043;
	/*border-radius: 50%;*/
}