.button {
	margin: 20px auto 0 auto;
	padding: 13px 35px;
	width: fit-content;
	background-color: var(--primary-color);
	color: #fff;
	border-radius: 8px;
	border: none;
	font-size: 15px;
	cursor: pointer;
}
.button:hover {
	opacity: 0.7;
	transition: all 0.5s;
}
.button:disabled {
	opacity: 0.7;
}