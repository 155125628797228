@font-face {
	font-family: "Raleway";
	src: local("RalewayRegular"),
	  url("./fonts/Raleway-Regular.ttf") format("truetype");
	font-weight: normal;
 }
 @font-face {
	font-family: "Raleway";
	src: local("RalewayMedium"),
	  url("./fonts/Raleway-Medium.ttf") format("truetype");
	font-weight: 500;
 }
 @font-face {
	font-family: "Raleway";
	src: local("RalewayLight"),
	  url("./fonts/Raleway-Light.ttf") format("truetype");
	font-weight: 300;
 }
 @font-face {
	font-family: "Raleway";
	src: local("RalewayLightIt"),
	  url("./fonts/Raleway-LightItalic.ttf") format("truetype");
	font-weight: 300;
 }
 @font-face {
	font-family: "Raleway";
	src: local("RalewayBold"),
	  url("./fonts/Raleway-Bold.ttf") format("truetype");
	font-weight: 700;
 }
 @font-face {
	font-family: "Raleway";
	src: local("RalewayBlack"),
	  url("./fonts/Raleway-Black.ttf") format("truetype");
	font-weight: 900;
 }
 @font-face {
	font-family: "Raleway";
	src: local("RalewayExtra"),
	  url("./fonts/Raleway-ExtraBold.ttf") format("truetype");
	font-weight: 800;
 }

* {
	margin: 0;
	padding: 0;
	text-decoration: none;
	box-sizing: border-box;
}
ul li {
	list-style: none;
}

body {
	--primary-color: #012043;
	--lightGrey-color: #C6CDD5;
	--grey-color: #606A7B;
	font-family: 'Raleway', sans-serif;
	color: var(--primary-color)
}

body::-webkit-scrollbar {
	display: none;
}

.screen, #root  {
	z-index: -1;
}

.back {
	position: absolute;
	top: 30px;
	left: 20px;
	height: 15px;
	display: flex;
	align-items: center;
	object-fit: contain;
	font-weight: 500;
	font-size: 16px;
	cursor: pointer;
	color: var(--primary-color);
	z-index: 30;
}

.back img {
	height: 100%;
	margin: 0px 8px 0px 0px;
}
.screenPage {
	display: flex;
	flex: 1;
	flex-direction: column;
	min-height: 100vh;
	padding: 90px 10px 20px 10px;
}
.screenHeader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 80px;
	display: flex;
	justify-content:center;
	align-items: center;
	padding: 0px 60px;
	background: rgba(255, 255, 255, 0.6);
	-webkit-backdrop-filter: blur(10px);
	backdrop-filter: blur(10px);
	font-size: 25px;
	color: var(--primary-color);
	font-weight: 800;
	z-index: 20;
}

.react-tel-input .form-control {
	width: 100% !important;
	height: 52px !important;
	font-size: 16px !important;
	font-weight: 600 !important;
	border: 2px solid var(--lightGrey-color) !important;
	border-radius: 8px !important;
	background-color: #fff !important;
	/*color: var(--lightGrey-color);*/
	color: var(--primary-color) !important;
}

.react-tel-input .form-control.invalid-number {
	border: 2px solid #d79f9f !important;
	background-color: #FAF0F0 !important;
	border-left-color: #cacaca !important;
}

.targetButton {
	cursor: pointer;
}
.targetButton::before {
	content: url('./assets/target.svg');
}