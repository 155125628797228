.card {
	position: relative;
	width: 100%;
	padding: 35px 30px 45px 30px;
	margin: 0px 0px 10px 0px;
	border-radius: 20px;
	cursor: pointer;
	overflow: hidden;
	/*background: #fff center/cover no-repeat;*/
}
.card:hover {
	opacity: 0.7;
	transition: all 0.3s;
}
.icon {
	margin: 0px 0px 10px 0px;
}
.icon img {
	width: 50px;
	height: 50px;
}

.card h2 {
	font-size: 40px;
	margin: 0px 0px 10px 0px;
	font-weight: 800;
	overflow: hidden;
	text-overflow: ellipsis;
}
.card p {
	font-size: 25px;
	font-weight: 500;
	overflow: hidden;
	text-overflow: ellipsis;
}

.overlay {
	position: absolute;
	background-color: rgba(114, 175, 211, 0.8);
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	border-radius: 20px;
	display: flex;
	justify-content:center;
	align-items: center;
}

.overlay img {
	width: 60px;
}

.watched {
	position: absolute;
	bottom: 0;
	right: 0;
	left: 0;
	height: 10px;
	background-color: #50FF9F;
	z-index: 10;
	border-radius: 20px;
}