.loginTop-enter {
	opacity: 0;
	transform: translateY(-100vh);
}

.loginTop-enter-active {
	opacity: 1;
	transform: translateY(0);
	transition: opacity 2000ms, transform 2000ms;
}

.loginTop-exit {
	opacity: 1;
}

.loginTop-exit-active {
	opacity: 0;
	transform: translateY(-100vh);
	transition: opacity 2000ms, transform 2000ms;
}

.loginBottom-enter {
	opacity: 0;
	transform: translateY(100vh);
}

.loginBottom-enter-active {
	opacity: 1;
	transform: translateY(0);
	transition: opacity 2000ms, transform 2000ms;
}

.loginBottom-exit {
	opacity: 1;
}

.loginBottom-exit-active {
	opacity: 0;
	transform: translateY(100vh);
	transition: opacity 2000ms, transform 2000ms;
}

.loginCode-enter {
	opacity: 0;
	transform: translateY(-50px);
}

.loginCode-enter-active {
	opacity: 1;
	transform: translateY(0);
	transition: opacity 2000ms, transform 2000ms;
}

.loginCode-exit {
	opacity: 1;
}

.loginCode-exit-active {
	opacity: 0;
	transform: translateY(-50px);
	transition: opacity 2000ms, transform 2000ms;
}


.loginResend-enter {
	opacity: 0;
}

.loginResend-enter-active {
	opacity: 1;
	transition: opacity 2000ms;
}

.loginResend-exit {
	opacity: 1;
}

.loginResend-exit-active {
	opacity: 0;
	transition: opacity 2000ms, transform 2000ms;
}


.libCards-enter {
	opacity: 0;
	transform: translateY(-250px);
}

.libCards-enter-active {
	opacity: 1;
	transform: translateY(0px);
	transition: opacity 2000ms, transform 2000ms;
}

.libCards-exit {
	opacity: 1;
}

.libCards-exit-active {
	opacity: 0;
	transform: translateY(-250px);
	transition: opacity 2000ms, transform 2000ms;
}


.moduleTop-enter {
	opacity: 0;
	/*transform: scale(0.6);*/
	transform: scale(1.2);
	/*transform: translateY(-200px);*/
}

.moduleTop-enter-active {
	opacity: 1;
	/*transform: scale(1);*/
	transform: scale(1);
	/*transform: translateY(0);*/
	transition: all 1500ms;
}

.moduleTop-exit {
	opacity: 1;
}

.moduleTop-exit-active {
	opacity: 0;
	/*transform: translateY(-200px);*/
	transition: all 1500ms;
}

.moduleBottom-enter {
	opacity: 0;
	transform: translateY(100vh);
}

.moduleBottom-enter-active {
	opacity: 1;
	transform: translateY(0);
	transition: all 800ms;
}

.moduleBottom-exit {
	opacity: 1;
}

.moduleBottom-exit-active {
	opacity: 0;
	transform: translateY(100vh);
	transition: all 800ms;
}
/*____________________________________________*/

.accordionContent-enter {
	opacity: 0;
	transform: rotateX(90deg);
}

.accordionContent-enter-active {
	opacity: 1;
	transform: rotateX(0);
	transition: all 500ms;
}

.accordionContent-exit {
	opacity: 1;
}

.accordionContent-exit-active {
	opacity: 0;
	transform: rotateX(90deg);
	transition: all 500ms;
}