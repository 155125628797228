.download {
	/*#0DC356*/
	position: relative;
	padding: 13px 60px 13px 20px;
	font-size: 18px;
	border: 2px solid var(--primary-color);
	background-color: #fff;
	border-radius: 8px;
	color: var(--primary-color);
	font-family: 'Raleway';
	margin: 0px 0px 0px 5px;
	cursor: pointer;
}
.download:disabled {
	opacity: 0.3;
}
.download:hover {
	opacity: 0.7;
	transition: all 0.5s;
}
.download::after {
	position: absolute;
	content: '';
	right: 20px;
	top: 13px;
	width: 20px;
	height: 20px;
	background: url('../assets/modules/download.svg') center/cover no-repeat;
}
.download.success {
	padding: 13px 44px 13px 15px;
	border: 2px solid #0DC356;
	color: #0DC356;
}
.success::after {
	right: 12px;
	background: url('../assets/modules/checkmark.svg') center/cover no-repeat;
}